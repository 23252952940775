<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
        <b-img
            fluid
           :src="appLogoImage"
            alt="ART of Mentoring"
          />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h1 class="mb-1">
          404
        </h1>
        <p class="mb-2">
          Oops! 😖 The page requested was not found.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

       
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue';
import store from '@/store/index';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BLink,
    BButton,
    BImg,
    
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      appLogoImage: $themeConfig.app.appLogoHImageB,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vue/pages/page-misc.scss';
h1{font-size: 200px;}
.misc-wrapper{
  min-height: unset;
}
</style>
